<template>
  <section
    id="recent-projects"
    class="overflow-hidden"
  >
    <v-row no-gutters class="primary">
      <v-col
        class="primary text-center pa-5 white--text"
        align-self="center"
        cols="12"
        md="6"
      >
        <base-heading class="pa-7 pa-md-3 mb-10">
          {{ $t("projects.title") }}
        </base-heading>
        <v-card color="secondary" width="80%" class="mx-auto">
          <v-container class="pa-2">
            <v-row>
              <v-col
                v-for="(project, i) in projects"
                :key="project"
                cols="12"
                md="6"
              >
                <v-hover
                    v-slot="{ hover }"
                    open-delay="200"
                >
                  <v-img
                    :src="require(`@/assets/${project}.jpg`)"
                    max-height="300"
                  >
                    <v-expand-transition>
                      <v-row
                          justify="center"
                          align-content="center"
                          v-if="hover || $vuetify.breakpoint.smAndDown "
                          class="transition-fast-in-fast-out accent text-h6 text-md-h3 white--text"
                          style="height: 105%; opacity: 0.7"
                      >
                        {{ $t(`projects.projects[${i}]`) }}
                      </v-row>
                    </v-expand-transition>
                  </v-img>
                </v-hover>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <base-text class="ma-5">
          {{ $t("projects.text") }}
        </base-text>
          <base-btn
              @click="$vuetify.goTo('#get-in-touch')"
              class="mt-4">
            {{ $t("projects.btn") }}
          </base-btn>
      </v-col>

      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
        <v-img
          :src="require('@/assets/gerhard_1.jpg')"
          height="100%"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'RecentProjects',

    data: () => ({
      projects: [
        'law',
        'care',
        'tech',
        'economy',
      ],
    }),
  }
</script>
